.exercise-card {
  /* UI Properties */
  background: var(--———-others-———) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-cacfd2);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cacfd2;
  border-radius: 5px;
  opacity: 1;
  padding-left: 1vw;
  min-height: 30vh;
  margin-bottom: 2vh;
}

.catalog-exercise-card {
  /* UI Properties */
  background: var(--———-others-———) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-cacfd2);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cacfd2;
  border-radius: 5px;
  opacity: 1;
  padding-left: 1vw;
  min-height: 30vh;
  margin-bottom: 2vh;
  overflow: hidden;
}

.body-region-title {
  letter-spacing: var(--unnamed-character-spacing-0-28);
  text-align: left;
  font: normal normal normal 14px/21px Kiro;
  letter-spacing: 0.28px;
  color: var(--main-color);
  opacity: 1;
  text-align: center;
}

.exercise-title {
  color: var(---000000-◀︎);
  text-align: left;
  font-family: "Kiro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.36px;
  color: #000000;
  opacity: 1;
  text-align: center;
}

.prescription {
  letter-spacing: var(--unnamed-character-spacing-0-28);
  color: var(---7c878e-◀︎);
  font: normal normal normal 16px/21px Kiro;
  letter-spacing: 0.28px;
  color: #7c878e;
  opacity: 1;
  text-align: center;
}

.exercise-description {
  overflow: hidden;
  max-height: 500px;
  transition: max-height 0.5s ease-out;
}

.video-content,
.catalog-video-content {
  width: 100%;
  height: 120px;
}

@media only screen and (min-width: 600px) {
  .video-content,
  .catalog-video-content {
    height: 150px;
  }
}

@media only screen and (min-width: 1300px) {
  .video-content,
  .catalog-video-content {
    height: 170px;
  }
}

@media only screen and (min-width: 1700px) {
  .video-content,
  .catalog-video-content {
    height: 200px;
  }
}

@media only screen and (min-width: 2000px) {
  .video-content,
  .catalog-video-content {
    height: 230px;
  }
}

@media only screen and (min-width: 2300px) {
  .video-content,
  .catalog-video-content {
    height: 250px;
  }
}

.catalog-exercise-description {
  margin-top: 10px;
  height: 240px;
  overflow: hidden;
}

.catalog-exericse-actions {
  margin-top: 20px;
  margin-bottom: 10px;
}

.provider-image {
  height: 25px;
}
.fixed-position {
  position: fixed;
}
.fixed-position-100 {
  position: fixed;
  width: 100%;
}

ul {
  list-style-position: outside;
}

.searchbar-exercises {
  margin-top: 10px;
  background: var(--unnamed-color-f5f5f5) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-e5e7e8);
  background: #f5f5f5 0% 0% no-repeat padding-box;
  width: 10vw;
  border: 1px solid #e5e7e8;
  border-radius: 20px;
  padding-left: 10px;
  opacity: 1;
  background-image: url(https://www.pngarea.com/pngm/109/1164446_searchicon-png-search-icon-png-download.png);
  background-size: 15px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 95%;
}
.searchbar-exercises-mobile {
  margin-top: 10px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  width: 100%;
  border: 1px solid #e5e7e8;
  border-radius: 20px;
  padding-left: 10px;
  opacity: 1;
  background-image: url(https://www.pngarea.com/pngm/109/1164446_searchicon-png-search-icon-png-download.png);
  background-size: 15px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 95%;
}

.program-sidebar-input {
  margin-top: 10px;
  background: var(--unnamed-color-f5f5f5) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-e5e7e8);
  width: 10vw;
  border: 1px solid #e5e7e8;
  border-radius: 20px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  opacity: 1;
  font-size: 14px;
}

.plus-icon {
  float: right;
  padding-right: 10px;
  padding-top: 6px;
}

.plus-icon-add-ex-wrapper {
  float: right;
}
.plus-icon-add-ex {
  float: right;
  padding-left: 5px;
  padding-top: 6px;
}

.searchbar-exercises::placeholder {
  font-size: 16px;
}

.filter-title-mobile {
  margin-top: 10px;
  text-align: right;
  letter-spacing: 0.32px;
  color: #7c878e;
  opacity: 1;
}

.exercise-overview-main-mobile {
  margin-bottom: 150px;
}

.exercise-card-header {
  height: 80px;
}

.custom-less-class {
  margin-top: 10px;
}

.truncate {
  max-height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.show-more-text {
  color: #7c878e;
  cursor: pointer;
}

.video-container.safari-fix video::-webkit-media-controls-play-button {
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

/* Hide custom play button by default */
.custom-play-button {
  display: none;
}
.safari-fix .custom-play-button {
  /* styles for the play button */
  display: block; /* This should override the default 'none' */
}

.exercise-video-player .safari-fix .custom-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 20px solid #8c8989;
  cursor: pointer;
  z-index: 1;
  opacity: 0.7; /* Make the button slightly transparent */
  transition: opacity 0.2s;
  display: block;
}

.safari-fix:hover .custom-play-button {
  display: block; /* Ensure this is shown on hover */
}

.safari-fix video::-webkit-media-controls-start-playback-button {
  display: none; /* Hide the default Safari play button */
}

/* Non-Safari browsers will ignore this */
.video-container.safari-fix {
  position: relative;
}

/* Mobile Screen Styles */
@media only screen and (min-width: 360px) {
  .mobile-width {
    min-width: 100%;
  }
  .program-exercise-row:last-child {
    margin-bottom: 150px;
  }
}

/* Tablet Screen Styles */
@media only screen and (min-width: 768px) {
  .truncate {
    max-height: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/* Notebook Small Screen Styles */
@media only screen and (min-width: 1024px) {
  .program-exercise-row:last-child {
    margin-bottom: 80px;
  }
}

/* Notebook Screen Styles */
@media only screen and (min-width: 1200px) {
  .truncate {
    max-height: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/* Large Screen Styles */
@media only screen and (min-width: 1400px) {
}
