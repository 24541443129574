.draggable-circle {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: var(--main-color);
  position: fixed;
  cursor: grab;
  z-index: 9999;
  top: 80%;
  left: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white; /* Optional: adds a border */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Optional: adds a shadow */
}

.draggable-circle:active {
  cursor: grabbing;
}

.white-icon-button {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-modal .modal-dialog {
  max-width: 90%; /* Default max-width for smaller screens */
}

/* Media query for larger screens (e.g., iPads) */
@media (min-width: 768px) {
  .custom-modal .modal-dialog {
    max-width: 80%; /* Increase this percentage for larger screens */
  }
}
