.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-button-parent {
  text-align: center;
}

.forgot-password-link {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  color: var(--main-color); /* Or any color you prefer */
  text-decoration: underline;
  cursor: pointer;
}

.forgot-password-link:focus {
  outline: none;
}

.login-button {
  margin-top: 1vh;
  background: var(--main-color) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  border-color: var(--main-color);
  opacity: 1;
  letter-spacing: var(--unnamed-character-spacing-0-18);
  color: var(--———-others-———);
  text-align: left;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
  width: 140px;
  text-align: center;
  padding: 5px 0px 2px 5px;
}

.login-field-wrapper {
  margin-top: 3vh;
  width: 100%;
}

.password-field-wrapper {
  margin-top: 1vh;
}

.input-field-login {
  width: 100%;

  min-height: 34px;
  height: 3vh;
  background: var(--———-others-———) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-cacfd2);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cacfd2;
  border-radius: 20px;
  opacity: 1;
  padding-left: 2em;
}

.forgot-password-link-wrapper {
  text-align: right;
}

.forgot-password-link {
  opacity: 40%;
  text-decoration: none;
  color: #000000;
}

.new-registration-text {
  margin-top: 2vh;
  letter-spacing: var(--unnamed-character-spacing-0-32);
  color: var(---7c878e-◀︎);
  text-align: center;
  letter-spacing: 0.32px;
  color: #7c878e;
  opacity: 1;
}

.new-account-link {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) /
    var(--unnamed-line-spacing-26) var(--unnamed-font-family-kiro);
  letter-spacing: var(--unnamed-character-spacing-0-32);
  color: var(---84bd00-◀︎);
  text-decoration: none;
  font: normal normal normal 16px/26px Kiro;
  letter-spacing: 0.32px;
  color: var(--main-color);
  opacity: 1;
  font-weight: bold;
}

.alert-fixed {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 9999;
  border-radius: 0px;
}

/* Mobile Screen Styles */
@media only screen and (min-width: 360px) {
  .loginContainer {
    margin-top: 10%;
    padding-top: 2vh;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 1vw;
    margin-right: 1vw;
    min-height: 350px;
    /* height: 50vh; */
    background: var(--———-others-———) 0% 0% no-repeat padding-box;
    border: 1pt solid var(--unnamed-color-e5e7e8);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1pt solid #e5e7e8;
    border-radius: 5pt;
    opacity: 1;
  }
  .sophyappimage {
    width: 50%;
  }
  .login-field-wrapper {
    margin-top: 3vh;
    width: 100%;
  }
}

/* Tablet Screen Styles */
@media only screen and (min-width: 768px) {
  .loginContainer {
    margin-top: 10%;
    padding-top: 2vh;
    margin-left: 15vw;
    margin-right: 15vw;
    min-height: 350px;
    /* height: 50vh; */
    background: var(--———-others-———) 0% 0% no-repeat padding-box;
    border: 1pt solid var(--unnamed-color-e5e7e8);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1pt solid #e5e7e8;
    border-radius: 5pt;
    opacity: 1;
  }
  .sophyappimage {
    width: 30%;
  }
}

/* Notebook Small Screen Styles */
@media only screen and (min-width: 1024px) {
  .loginContainer {
    margin-top: 10%;
    padding-top: 2vh;
    margin-left: 15vw;
    margin-right: 15vw;
    min-height: 350px;
    /* height: 50vh; */
    background: var(--———-others-———) 0% 0% no-repeat padding-box;
    border: 1pt solid var(--unnamed-color-e5e7e8);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1pt solid #e5e7e8;
    border-radius: 5pt;
    opacity: 1;
  }
  .sophyappimage {
    width: 30%;
  }
}

/* Notebook Screen Styles */
@media only screen and (min-width: 1200px) {
  .loginContainer {
    margin-top: 10%;
    padding-top: 2vh;
    margin-left: 15vw;
    margin-right: 15vw;
    min-height: 350px;
    /* height: 50vh; */
    background: var(--———-others-———) 0% 0% no-repeat padding-box;
    border: 1pt solid var(--unnamed-color-e5e7e8);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1pt solid #e5e7e8;
    border-radius: 5pt;
    opacity: 1;
  }
  .sophyappimage {
    width: 30%;
  }
}

/* Large Screen Styles */
@media only screen and (min-width: 1400px) {
  .loginContainer {
    margin-top: 10%;
    padding-top: 48px;
    margin-left: 32%;
    margin-right: 32%;
    height: 46.5vh;
    background: var(--———-others-———) 0% 0% no-repeat padding-box;
    border: 1pt solid var(--unnamed-color-e5e7e8);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1pt solid #e5e7e8;
    border-radius: 5pt;
    opacity: 1;
  }
  .sophyappimage {
    width: 35%;
  }
}
