@media only screen and (min-width: 1440px) {
  .app-menu {
    transition: width 0.5s ease;
    width: 50px;
    overflow-x: hidden;
  }

  .app-menu:hover {
    width: 200px;
  }

  .menu-title {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .app-menu:hover .menu-title {
    opacity: 1;
  }
}

.menu-footer {
  position: fixed;
  bottom: 1vh;
  padding-left: 8px;
  padding-right: 8px;
  width: 50px;
  transition: width 0.5s ease;
}

.app-menu:hover .menu-footer {
  width: 200px;
}

.menu-footer-title {
  display: flex;
  align-items: center;
}

.copyright-icon {
  opacity: 1;
  transition: opacity 0.3s ease;
  color: black;
}

.footer-text {
  opacity: 0;
  transition: opacity 0.3s ease;
  color: black;
  white-space: nowrap;
  margin-left: 8px;
}

.app-menu:hover .footer-text {
  opacity: 1;
}

@media only screen and (max-width: 1439px) {
  .copyright-icon {
    opacity: 1;
  }

  .footer-text {
    display: none;
  }
}
