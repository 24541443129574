.spinner {
  display: block;
  position: fixed;
  z-index: 1031; /* High z-index so it is on top of the page */
  top: calc(50% - (...px / 2)); /* where ... is the element's height */
  right: calc(50% - (...px / 2)); /* where ... is the element's width */
}

.spinner-exercise-upload {
  display: block;
  position: fixed;
  z-index: 1031; /* High z-index so it is on top of the page */
  top: 40%; /* where ... is the element's height */
  right: 40%; /* where ... is the element's width */
}

.carousel-control-prev {
  height: 300px;
}

.carousel-control-next {
  height: 300px;
}

.carousel .carousel-control-prev-icon {
  visibility: hidden;
}

.carousel:hover .carousel-control-prev-icon {
  visibility: visible;
}

.breadcrumb-space {
  border-top: 1px solid #e5e7e8;
  border-bottom: 1px solid #e5e7e8;
  letter-spacing: var(--unnamed-character-spacing-0-28);
  color: var(---7c878e-◀︎);
  text-align: left;
  font: normal normal normal 14px/26px Kiro;
  letter-spacing: 0.28px;
  color: #7c878e;
  opacity: 1;
}
.custom-template {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 16px;
}
.modal-body-fileupload {
  max-height: 600px;
  overflow-y: scroll;
}

.react-dropzone-container {
  cursor: pointer;
  height: 200px;
  background: #f5f9ea 0% 0% no-repeat padding-box;
  border: 1px dashed var(--main-color);
  border-radius: 5px;
  opacity: 1;
}

.react-dropzone-container-text {
  text-align: center;
  font: normal normal normal 20px/30px Kiro;
  letter-spacing: 0px;
  color: var(--main-color);
  opacity: 1;
  top: 50%;
  padding-top: 80px;
}

.uploaded-exercise-wrapper {
  background: #f5f9ea 0% 0% no-repeat padding-box;
  border: 2px solid var(--main-color);
  border-radius: 5px;
  opacity: 1;
  margin-top: 5px;
  width: 15%;
  display: inline-block;
  margin-right: 5px;
  height: 100px;
  /* background: transparent url("../../../public/images/image_placeholder.png") 0%
    0% no-repeat padding-box;
  background-size: cover; */
}

.uploaded-exercise-wrapper-btn-remove {
  max-width: 100%;
}

.delete-button {
  background-color: darkred;
  margin-left: 10px;
  border: 2px solid darkred;
}
.delete-button:hover {
  background-color: red;
  color: white;
  border: 2px solid darkred;
}

.btn-disabled {
  background-color: lightgray !important;
  color: #333 !important;
}

.list-item-action-icon {
  width: 18px;
  height: 18px;
}

.list-item-image-modal {
  height: 130px;
}

.list-item-modal {
  height: 150px;
}

.list-container-modal {
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: 70vh;
  overflow-y: scroll;
}

.modal-preview {
  height: 80vh;
}

.modal-preview .modal-body {
  overflow-y: auto;
}
