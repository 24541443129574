.exercise-inputs .input-group {
  margin-bottom: 10px;
}

.exercise-inputs .input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.exercise-inputs .input-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.exercise-inputs .input-group-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.exercise-inputs .input-group-row .input-group {
  flex: 1;
  margin-right: 10px;
}

.exercise-inputs .input-group-row .input-group:last-child {
  margin-right: 0;
}

.exercise-inputs .input-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

/* You might want to add styles for the focus state */
.exercise-inputs .input-group select:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
