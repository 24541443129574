.sidebar-properties {
  padding-top: 2vh;
  padding-left: 1vw;
  border-right: #dcdcdc solid;
}
.sidebar-properties-patient-list {
  padding-top: 2vh;
  padding-left: 1vw;
  /* border-right: #dcdcdc solid; */
}
.sidebar-header {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---84bd00-◀︎);
  text-align: left;
  font: normal normal normal 20px/30px Kiro;
  letter-spacing: 0px;
  color: var(--main-color);
  opacity: 1;
  border-bottom: 1px #dcdcdc solid;
}
.sidebar-header-mobile {
  text-align: left;
  font: normal normal normal 20px/30px Kiro;
  letter-spacing: 0px;
  color: var(--main-color);
  opacity: 1;
  /* border-bottom: 1px #dcdcdc solid; */
  margin-left: 10px;
}
.sub-menu-item {
  margin-bottom: 5vh;
  letter-spacing: var(--unnamed-character-spacing-0-32);
  color: var(---7c878e-◀︎);
  text-align: left;
  font: normal normal normal 16px/24px Kiro;
  letter-spacing: 0.32px;
  color: #7c878e;
  opacity: 1;
}

.sb-main-content {
  margin-top: 10px;
}

.sb-main-content-header {
  padding-top: 2vh;
  border-bottom: 1px #dcdcdc solid;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---000000-◀︎);
  text-align: left;
  font: normal normal normal 20px/30px Kiro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

a.active .sub-menu-item {
  letter-spacing: var(--unnamed-character-spacing-0-32);
  text-align: left;
  letter-spacing: 0.32px;
  color: #000000;
  opacity: 1;
  font-weight: bold;
}

.table-column-title {
  letter-spacing: var(--unnamed-character-spacing-0-3);
  color: var(---7c878e-◀︎);
  text-align: left;
  font: normal normal normal 15px/24px Kiro;
  letter-spacing: 0.3px;
  color: #7c878e;
  opacity: 1;
}

.table-row {
  margin-top: 2vh;
}

.profile-input-field {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cacfd2;
  border-radius: 20px;
  opacity: 1;
  padding-left: 0.5vw;
}

.about-sop {
  margin-top: 3vh;
  margin-bottom: 100px;
}

.disclaimer {
  margin-top: 10vh;
}

.select-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cacfd2;
  border-radius: 20px;
  opacity: 1;
  padding-left: 0.5vw;
  /*min-width: 10vw;*/
}

.general-settings-header {
  margin-top: 20px;
  color: var(---000000-◀︎);
  text-align: left;
  font: normal 16px Kiro;
  font-weight: bold;
  letter-spacing: 0.32px;
  color: #000000;
  opacity: 1;
}

.client-preview {
  text-align: left;
  font: normal normal normal 16px/24px Kiro;
  letter-spacing: 0.32px;
  color: #7c878e;
  opacity: 1;
}

.accordion-style {
  background-color: var(--main-color);
}

/* Mobile Screen Styles */
@media only screen and (min-width: 360px) {
  /* .sidebar-properties {
    display: none;
  } */
  .preference-row-mobile {
    border-bottom: 1px #d7dee3 solid;
  }
  .wrapper-mobile {
    margin-bottom: 150px;
  }
  .sidebar-properties-mobile {
    padding-top: 2vh;
    padding-left: 20px;
    margin-bottom: 100px;
  }

  .about-sop {
    margin-bottom: 130px;
  }
}

/* Tablet Screen Styles */
@media only screen and (min-width: 768px) {
  /* .sidebar-properties {
    display: block;
  } */
  .previewLogo {
    margin-top: 5px;
    width: 60px;
  }
}

@media only screen and (min-width: 900px) {
  .preference-row-mobile {
    border-bottom: none;
  }
  .wrapper-mobile {
    margin-bottom: 150px;
  }
  .sidebar-properties-mobile {
    padding-top: 0;
    padding-left: 0;
  }
}

/* Notebook Small Screen Styles */
@media only screen and (min-width: 1024px) {
  .previewLogo {
    margin-top: 10px;
    width: 100px;
  }
}

/* Notebook Screen Styles */
@media only screen and (min-width: 1200px) {
  .previewLogo {
    margin-top: 10px;
    width: 150px;
  }
}

/* Large Screen Styles */
@media only screen and (min-width: 1400px) {
  .previewLogo {
    margin-top: 10px;
    width: 200px;
  }
}
