.patient-name {
  display: inline-block;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.patient-container {
  display: flex;
  align-items: center;
}

.mobile-content-main {
  padding-bottom: 80px;
}
/* Mobile Screen Styles */
@media only screen and (min-width: 360px) {
  .search-icon {
    float: right;
    height: 18px;
    padding-top: 4px;
  }
  .sidebar-patient-list {
    margin-top: 10px;
    border-top: 1px solid #e5e7e8;
    padding-top: 10px;
    height: 80vh;
    overflow-y: scroll;
    margin-bottom: 130px;
  }

  .patient-preview {
    cursor: pointer;
    margin-bottom: 5px;
  }

  .patient-preview:last-child {
    margin-bottom: 100px;
  }

  .patient-preview-active {
    cursor: pointer;
    margin-bottom: 5px;
    border: 1px solid var(---84bd00-◀︎);
    background: var(--main-color) 0% 0% no-repeat padding-box;
    border: 1px solid var(--main-color);
    border-radius: 20px;
    color: white;
    opacity: 1;
  }

  .patient-preview:hover {
    border: 1px solid var(--unnamed-color-e5e7e8);
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border: 1px solid #e5e7e8;
    border-radius: 20px;
    opacity: 1;
  }

  .patient-preview:active {
    background: var(---84bd00-◀︎) 0% 0% no-repeat padding-box;
    border: 1px solid var(---84bd00-◀︎);
    background: var(--main-color) 0% 0% no-repeat padding-box;
    border: 1px solid var(--main-color);
    border-radius: 20px;
    color: white;
    opacity: 1;
  }

  .patient-preview-icon {
    margin-right: 10px;
    height: 40px;
  }

  .patient-back-button {
    text-align: left;
    letter-spacing: 0.18px;
    color: var(--main-color);
    font-size: 18px;
    font-weight: bold;
    margin-left: 5px;
    opacity: 1;
  }

  .patient-mobile-actions {
    margin-top: 10px;
  }

  .patient-mobile-icons {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cacfd2;
    border-radius: 10px;
    opacity: 1;
    display: grid;
    place-items: center;
    padding-top: 5px;
    height: 50px;
    width: 80px;
  }
  .patient-mobile-icon-new-program {
    background: var(--main-color) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    display: grid;
    place-items: center;
    padding-top: 5px;
    height: 50px;
    width: 80px;
  }
  .profile-card-mobile {
    margin-bottom: 20px;
  }
  .patient-mobile-icons-text {
    color: #7c878e;
  }
  .patient-mobile-new-program-text {
    color: white;
  }
}

/* Tablet Screen Styles */
@media only screen and (min-width: 768px) {
  .searchbar-patient {
    margin-top: 10px;
    width: 19vw;
  }

  .sidebar-patient-list {
    margin-top: 10px;
    border-top: 1px solid #e5e7e8;
    padding-top: 10px;
    height: 80vh;
    overflow-y: scroll;
    margin-bottom: 100px;
  }

  .sidebar-exercise-list {
    margin-top: 10px;
    padding-top: 10px;
    height: 80vh;
  }

  .patient-preview {
    cursor: pointer;
    margin-bottom: 5px;
  }

  .patient-preview-active {
    cursor: pointer;
    margin-bottom: 5px;
    border: 1px solid var(---84bd00-◀︎);
    background: var(--main-color) 0% 0% no-repeat padding-box;
    border: 1px solid var(--main-color);
    border-radius: 20px;
    color: white;
    opacity: 1;
  }

  .patient-preview:hover {
    border: 1px solid var(--unnamed-color-e5e7e8);
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border: 1px solid #e5e7e8;
    border-radius: 20px;
    opacity: 1;
  }

  .patient-preview:active {
    background: var(---84bd00-◀︎) 0% 0% no-repeat padding-box;
    border: 1px solid var(---84bd00-◀︎);
    background: var(--main-color) 0% 0% no-repeat padding-box;
    border: 1px solid var(--main-color);
    border-radius: 20px;
    color: white;
    opacity: 1;
  }

  .patient-preview-icon {
    margin-right: 10px;
    height: 40px;
    width: 40px;
  }

  .patient-header-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .patient-header-icon {
    height: 72px;
    max-width: 72px;
  }

  .patient-header-details-name {
    font-size: 20px;
  }

  .patient-header-details-mail {
    letter-spacing: var(--unnamed-character-spacing-0-28);
    color: var(---7c878e-◀︎);
    text-align: left;
    font: normal normal normal 14px/21px Kiro;
    letter-spacing: 0.28px;
    color: #7c878e;
    opacity: 1;
  }

  .patient-header-details-diagnose {
    letter-spacing: var(--unnamed-character-spacing-0-28);
    color: var(---84bd00-◀︎);
    text-align: left;
    font: normal normal normal 14px/21px Kiro;
    letter-spacing: 0.28px;
    color: var(--main-color);
    opacity: 1;
  }

  .patient-header-btn {
    border: 1px solid var(--unnamed-color-cacfd2);
    border: 1px solid #cacfd2;
    height: 32px;
    border-radius: 20px;
    opacity: 1;
    width: auto;
    margin-right: 1vw;
    letter-spacing: var(--unnamed-character-spacing-0-3);
    color: var(---7c878e-◀︎);
    text-align: left;
    font: normal normal normal 15px/26px Kiro;
    letter-spacing: 0.3px;
    color: #7c878e;
    opacity: 1;
    cursor: pointer;
  }

  .patient-header-btn-new-program {
    border: 1px solid var(--unnamed-color-cacfd2);
    border: 1px solid #cacfd2;
    height: 32px;
    background: var(---84bd00-◀︎) 0% 0% no-repeat padding-box;
    background: var(--main-color) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    width: auto;
    letter-spacing: var(--unnamed-character-spacing-0-3);
    color: var(--———-others-———);
    text-align: left;
    font: normal normal normal 15px/26px Kiro;
    letter-spacing: 0.3px;
    color: #ffffff;
    opacity: 1;
    cursor: pointer;
  }

  .patient-header-btn-icon {
    float: right;
    padding-left: 3px;
    padding-top: 8px;
    height: 20px;
    width: 20px;
  }
}

/* Notebook Small Screen Styles */
@media only screen and (min-width: 1024px) {
  .searchbar-patient {
    margin-top: 10px;
    width: 12vw;
  }
}

/* Notebook Screen Styles */
@media only screen and (min-width: 1200px) {
  .searchbar-patient {
    margin-top: 10px;
    width: 10vw;
  }
}

/* Large Screen Styles */
@media only screen and (min-width: 1400px) {
  .searchbar-patient {
    margin-top: 10px;
    min-width: 10vw;
  }
}
