.exercise-card-preview {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  align-items: center;
  max-height: none;
  overflow: visible;
  position: relative;
  padding-left: 1vw;
  min-height: 30vh;
  margin-bottom: 2vh;
}

.no-exercises {
  text-align: center;
  color: #6c757d;
  padding: 20px;
}

.toggle-grid-size {
  margin-bottom: 10px;
}

.exercise-card-preview.column-layout {
  flex-direction: column;
}

.exercise-image {
  width: auto;
  max-width: 100%;
  height: auto;
  margin-right: 10px;
  object-fit: contain;
  margin-top: 10px;
  border-radius: 4px;
  max-height: 200px;
}
.exercise-order {
  font-weight: bold;
  margin-right: 10px;
  margin-right: 10px;
}

.reorder-info {
  margin-left: 10px;
  font-size: 0.8em;
  color: #6c757d;
}

.exercise-card-close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #888;
  padding: 4px;
}

.show-description-preview-text {
  color: var(--main-color);
  cursor: pointer;
  margin-top: 10px;
}

.exercise-preview-description {
  margin-top: 10px;
  text-align: left;
}
.show-description-preview-text:hover {
  text-decoration: underline;
}

.exercise-preview-description ul {
  padding-left: 20px;
  list-style-type: disc;
}

.exercise-preview-description li {
  text-align: left;
  margin-bottom: 5px;
}

.toggle-grid-size:hover {
  color: var(lightgrey);
  border-color: var(--main-color);
}

@media (max-width: 900px) {
  .exercise-card-preview {
    flex-direction: column;
    align-items: flex-start;
  }

  .exercise-preview-description {
    width: 100%;
  }
}
